import React, { useEffect, useState } from "react";
import styles from "./BlogInfo.module.css";
import innerBLogBaby from "../../Assets/innerBLogBaby.png";
import infoImg from "../../Assets/infoImg.png";
import { useParams } from "react-router-dom";
import { open_api } from "../../Utils/network";
import { Rating } from "@mui/material";
import { Helmet } from "react-helmet";
import { FaFacebook, FaTwitter, FaLinkedin, FaShareAlt } from "react-icons/fa";
import ArticleHeader from "./ArticleHeader";

function BlogInfo({ data }) {
  const [blogReviews, setBlogReviews] = useState();
  const [blogMain, setBlogMain] = useState();
  const { slug } = useParams();

  useEffect(() => {
    if (slug) {
      getBlogComments(slug);
    }
  }, [slug]);

  const getBlogComments = (slug) => {
    open_api
      .get(`blog/pages/${slug}/comments/`)
      .then((res) => {
        setBlogReviews(res?.data?.data);
        setBlogMain(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleShare = (platform) => {
    const shareUrl = window.location.href; // Current page URL
    const title = document.title; // Page title (or you can use article title)

    switch (platform) {
      case "facebook":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
          "_blank"
        );
        break;
      case "twitter":
        window.open(
          `https://twitter.com/intent/tweet?url=${shareUrl}&text=${title}`,
          "_blank"
        );
        break;
      case "linkedin":
        window.open(
          `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}&title=${title}`,
          "_blank"
        );
        break;
      case "common":
        if (navigator.share) {
          navigator
            .share({
              title: title,
              url: shareUrl,
            })
            .catch(console.error);
        } else {
          alert("Your browser doesn't support the Web Share API.");
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Helmet>
        <title>{data?.meta?.seo_title || data?.title}</title>
        <meta
          name="description"
          content={data?.meta?.search_description || data?.intro}
        />
      </Helmet>

      <ArticleHeader data={data} />
      <div className={styles.container}>
        {/* Header */}

        {/* Blog Content */}
        <div className={styles.content}>
          {data?.body?.map((block) => {
            switch (block.type) {
              case "h1":
                return <h1 key={block.id}>{block.value}</h1>;
              case "h2":
                return <h2 key={block.id}>{block.value}</h2>;
              case "paragraph":
                return (
                  <p
                    key={block.id}
                    dangerouslySetInnerHTML={{ __html: block.value }}
                  />
                );
              case "image_carousel":
                return (
                  <div key={block.id} className={styles.imageCarousel}>
                    {block.value.map((image, idx) => (
                      <img
                        key={idx}
                        src={image.url}
                        alt={`Carousel Image ${idx + 1}`}
                        className={styles.carouselImage}
                      />
                    ))}
                  </div>
                );
              case "thumbnail_gallery":
                return (
                  <div key={block.id} className={styles.thumbnailGallery}>
                    {block.value.map((image, idx) => (
                      <img
                        key={idx}
                        src={image.url}
                        alt={`Thumbnail Image ${idx + 1}`}
                        className={styles.thumbnailImage}
                      />
                    ))}
                  </div>
                );
              case "image_text":
                return (
                  <div key={block.id} className={styles.imageTextBlock}>
                    <img
                      src={block.value.image.url}
                      alt="Text with Image"
                      className={styles.imageTextImage}
                    />
                    <div
                      dangerouslySetInnerHTML={{
                        __html: block.value.text,
                      }}
                      className={styles.imageTextContent}
                    />
                  </div>
                );
              default:
                return null;
            }
          })}
        </div>
      </div>
    </>
  );
}

export default BlogInfo;
