import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  SuccessMessage,
  Label,
  InputField,
  ErrorText,
  Select,
} from "../Dialog/PaymentDialogStyles"; // Adjust path as needed

const UserProfileForm = ({
  useEmail,
  email,
  phoneNumber,
  usePhone,
  firstName,
  lastName,
  profileType,
  profileTypes,
  firstNameError,
  lastNameError,
  phoneNumberError,
  emailError,
  profileTypeError,
  handlefirstNameChange,
  handlelastNameChange,
  handlePhoneNumberInputChange,
  handleEmailInputChange,
  handleProfileTypeChange,
}) => {
  return (
    <div>
      <SuccessMessage>
        <CheckCircleIcon color="success" />
        <h3>
          Your {useEmail ? "email" : "phone number"}{" "}
          {useEmail ? email : `+91 ${phoneNumber}`} has been verified
          successfully
        </h3>
      </SuccessMessage>

      <Label>First Name:</Label>
      <InputField
        name="firstName"
        type="text"
        placeholder="Enter your first name"
        value={firstName}
        onChange={handlefirstNameChange}
        style={{
          borderColor: firstNameError ? "red" : "#ccc",
        }}
      />
      {firstNameError && (
        <ErrorText>
          This field is required and must contain only letters
        </ErrorText>
      )}

      <Label>Last Name:</Label>
      <InputField
        name="lastName"
        type="text"
        placeholder="Enter your last name"
        value={lastName}
        onChange={handlelastNameChange}
        style={{
          borderColor: lastNameError ? "red" : "#ccc",
        }}
      />
      {lastNameError && (
        <ErrorText>
          This field is required and must contain only letters
        </ErrorText>
      )}

      <Label>Phone Number:</Label>
      <InputField
        name="phoneNumber"
        type="number"
        placeholder="Please enter your phone number"
        value={phoneNumber}
        onChange={handlePhoneNumberInputChange}
        style={{
          borderColor: phoneNumberError ? "red" : "#ccc",
        }}
        disabled={usePhone}
      />
      {phoneNumberError && (
        <ErrorText>
          This field is required and enter a valid phone number
        </ErrorText>
      )}

      <Label>Email:</Label>
      <InputField
        name="email"
        type="email"
        placeholder="Enter your email id"
        value={email}
        onChange={handleEmailInputChange}
        style={{
          borderColor: emailError ? "red" : "#ccc",
        }}
        disabled={useEmail}
      />
      {emailError && (
        <ErrorText>This field is required and enter a valid email</ErrorText>
      )}

      <Label htmlFor="profileType">Select Profile Type:</Label>
      <Select
        id="profileType"
        name="profileType"
        value={
          profileTypes.find((type) => type.title === profileType)?.id || ""
        }
        onChange={handleProfileTypeChange}
        style={{
          borderColor: profileTypeError ? "red" : "#ccc",
        }}
      >
        <option value="">Select a profile type</option>
        {profileTypes.map((type) => (
          <option key={type.id} value={type.id}>
            {type.title}
          </option>
        ))}
      </Select>
      {profileTypeError && <ErrorText>This field is required</ErrorText>}
    </div>
  );
};

export default UserProfileForm;
