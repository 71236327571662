// src/components/Dialog/PaymentDialog.jsx
import React, { useState, useEffect, useRef } from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  RightSection,
  Card,
  Button,
  OtpContainer,
  ServiceCard,
  AmountCard,
  OtpInputContainer,
  ResendOtpLink,
  SuccessMessage,
  Label,
  InputField,
  ErrorText,
  CouponContainer,
  CouponInput,
  ApplyButton,
  PayButton,
  NoPackagesContainer,
  NoPackagesImage,
  NoPackagesText,
  Select,
} from "./PaymentDialogStyles";
import {
  LeftContainer,
  Header,
  ExpertInfo,
  ExpertImage,
  ExpertDetails,
  ExpertName,
  ExpertDescription,
  PackageHeader,
  PackageContainer,
  PackageCard,
  PackageTitle,
  PackagePrice,
  DialogContainer,
  InnerCardContainer,
  InnerCard,
  ChangeLink,
  InnerCardFlex,
} from "./BookSessionDialogStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { duration, useTheme } from "@mui/material/styles";
import {
  open_api,
  open_api_without_auth,
  open_api_with_user_token,
  isProduction,
} from "../../Utils/network";
import ConfirmationDialog from "./ConfirmationDialog";
import LoadingOverlay from "./LoadingOverlay";
import checkIcon from "../../Assets/dialog/checkIcon.svg";
import failureIcon from "../../Assets/dialog/failureIcon.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import CongratulationsBanner from "../SuccessCard/CongratulationsBanner";
import rahela from "../../Assets/course_page/rahela.png";
import DateAvailability from "./DateAvailability";
import CustomAlert from "../AlertDialog/CustomAlert";
import emptyBox from "../../Assets/Logo_icons/emptybox.svg";
import {
  fetchCountryCode,
  validatePhoneNumber,
  validateEmail,
  handleOTPChange,
  handleKeyDown,
  handlePhoneNumberChange,
  handleEmailChange,
  applyCoupon,
  requestOTP,
  validateOTP,
  addAlert,
  removeAlert,
  fetchProfileTypes,
  handleCouponChange,
  checkUserInfoExists,
  fetchUserDetails,
  postExpertUserPurchase,
  validateGoogleLoginWithToken,
} from "./helper";
import useAuth from "../Hook/useAuth";
import successIcon from "../../Assets/feedback/successIcon.svg";
import FailedIcon from "../../Assets/feedback/failedIcon.svg";
import { useGoogleLogin, GoogleLogin } from "@react-oauth/google";
import googleLoginIcon from "../../Assets/login/googleLogin.svg";
import LoginCard from "../Login/LoginCard";

const BookSessionDialog = ({ open, onClose, expertDetails, packages }) => {
  const [useEmail, setUseEmail] = useState(false);
  const [usePhone, setUsePhone] = useState(false);
  const [contactValue, setContactValue] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [otpValidated, setOtpValidated] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setlastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [countryCode, setCountryCode] = useState("in"); // Default to US
  const inputRefs = useRef([]);
  const [coupon, setCoupon] = useState("");
  const [couponList, setCouponList] = useState([]);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponFound, setCouponFound] = useState(false);
  const [bearerLocalToken, setBearerToken] = useState("");
  const [userLocalId, setUserId] = useState(0);
  const [showRightSection, setShowRightSection] = useState(false);
  const [sessionCartId, setSessionCartId] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [newSessionPackagePrice, setNewSessionPackagePrice] = useState(0);
  const [matchingCoupon, setMatchingCoupon] = useState({});
  const [resentOTP, setResentOTP] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [profileType, setProfileType] = useState("");
  const [profileTypeId, setProfileTypeId] = useState(0);
  const [profileTypeError, setProfileTypeError] = useState(false);
  const [profileTypes, setProfileTypes] = useState([]);
  const { isAuthenticated, userName, userId, bearerToken, setIsAuthenticated } =
    useAuth();
  const [isUserInfoExists, setIsUserInfoExists] = useState(false);
  const [userLocalName, setUserName] = useState("");
  const [isUserDataRequired, setIsUserDataRequired] = useState(false);
  const [message, setMessage] = useState("");
  const [enablePayButton, setEnablePayButton] = useState(false);

  useEffect(() => {}, [isAuthenticated]);
  useEffect(() => {}, [email]);

  useEffect(() => {
    //console.log("expertDetails", expertDetails);
    //console.log("packages", packages);
    const updateUserDetails = async () => {
      if (isAuthenticated && bearerToken && userId) {
        // Ensure all necessary data is available
        const sessionUseEmail = sessionStorage.getItem("useEmail");
        const sessionUsePhone = sessionStorage.getItem("usePhone");
        setUseEmail(sessionUseEmail === "true");
        setUsePhone(sessionUsePhone === "true");
        setBearerToken(bearerToken);
        setUserName(userName);
        setUserId(userId);
        const userInfo = await fetchUserDetails(bearerToken, userId);
        setPhoneNumber(userInfo?.phone);
        setEmail(userInfo?.email);
        setfirstName(userInfo?.first_name);
        setlastName(userInfo?.last_name);
        const isUserInfoComplete = checkUserInfoExists(
          userInfo?.first_name,
          userInfo?.last_name,
          userInfo?.email,
          userInfo?.phone,
          sessionUseEmail
        );
        setIsUserInfoExists(isUserInfoComplete);
        if (isUserInfoComplete) {
          setEnablePayButton(true);
        }
      }
    };
    updateUserDetails();
  }, [isAuthenticated, bearerToken, userId, userName]);

  useEffect(() => {
    const getCountryCode = async () => {
      const countryCode = await fetchCountryCode();
      setCountryCode(countryCode);
    };
    getCountryCode();
  }, [countryCode]);

  const priceSymbol = countryCode === "in" ? "₹" : "$";
  const isUSD = countryCode === "in" ? "INR" : "USD";

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && resentOTP) {
      setResentOTP(false);
    }
  }, [countdown, resentOTP]);

  useEffect(() => {
    const fetchTypes = async () => {
      var accessToken = bearerLocalToken;
      if (accessToken === "") {
        accessToken = localStorage.getItem("bearerToken");
      }
      await fetchProfileTypes(accessToken, setProfileTypes);
    };
    fetchTypes();
  }, [bearerLocalToken]);

  const handleApplyCoupon = async () => {
    var sellingPrice = selectedSessionPackage.selling_price;
    if (countryCode != "in") {
      sellingPrice = selectedSessionPackage.selling_price_usd;
    }
    await applyCoupon(
      coupon,
      null,
      selectedSessionPackage.id,
      bearerLocalToken,
      sellingPrice,
      setShowAlert,
      handleAddAlert,
      setCouponFound,
      setMatchingCoupon,
      setNewSessionPackagePrice,
      setDiscountAmount,
      setCoupon
    );
  };

  const handleToggle = () => {
    setUseEmail(!useEmail);
    setContactValue("");
  };

  const handleRequestOTP = () => {
    setEmailError(false);
    setPhoneNumberError(false);
    if (
      useEmail
        ? validateEmail(email, setEmailError, setShowAlert, handleAddAlert)
        : validatePhoneNumber(
            phoneNumber,
            setPhoneNumberError,
            setShowAlert,
            handleAddAlert
          )
    ) {
      requestOTP(phoneNumber, setOtpRequested, setShowAlert, handleAddAlert);
      // Request OTP logic here
    } else {
    }
  };

  const handleSubmitOTP = async () => {
    await handleValidateOTP();
  };

  const handleResendOTP = () => {
    handleRequestOTP();
    setResentOTP(true);
    setCountdown(30);
  };

  const handlePhoneInputChange = (value, data) => {
    handlePhoneNumberChange(
      value,
      data,
      setCountryCode,
      setPhoneNumber,
      setPhoneNumberError
    );
  };

  const handleEmailInputChange = (e) => {
    handleEmailChange(e, setEmail, setEmailError);
  };

  const handleProfileTypeChange = (e) => {
    const selectedId = e.target.value;
    const selectedType = profileTypes.find(
      (type) => type.id.toString() === selectedId
    );
    if (selectedType) {
      setProfileType(selectedType.title);
      setProfileTypeId(selectedType.id);
    } else {
      setProfileType("");
    }
    setProfileTypeError(false);
  };

  const handlePhoneNumberInputChange = (e) => {
    if (e.target.name === "phoneNumber") {
      setPhoneNumberError(false);
    }
    setPhoneNumber(e.target.value);
  };

  const handlefirstNameChange = (e) => {
    if (e.target.name === "firstName") {
      setfirstNameError(false);
    }
    setfirstName(e.target.value);
  };

  const handlelastNameChange = (e) => {
    if (e.target.name === "lastName") {
      setlastNameError(false);
    }
    setlastName(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setUseEmail(false);
      setUsePhone(false);
      setOtpRequested(false);
      setContactValue("");
      setPaymentSuccessful(false);
      setVerified(false);
      setEmail("");
      setPhoneNumber("");
      setfirstName("");
      setPhoneNumberError(false);
      setEmailError(false);
      setfirstNameError(false);
      onClose();
      setOtp(Array(4).fill(""));
      setCouponFound(false);
      setCoupon([]);
      setDiscountAmount(0);
      setBearerToken("");
      setUserId(0);
      setOtpValidated(false);
      setCountryCode("in");
      setSelectedDate(null);
      setSelectedPackage(null);
      setSelectedSessionPackage([]);
      setSelectedTimeSlot(null);
      setShowPaymentSection(false);
      setShowDateAvialbilitySection(false);
      setChangePackage(false);
      setSelectedSlot(null);
      setShowAlert(false);
      setProfileTypes([]);
      setProfileType("");
      setProfileTypeError(false);
      setMessage("");
      if (!isAuthenticated) {
        setEmail("");
        setPhoneNumber("");
        setfirstName("");
        setlastName("");
        setBearerToken("");
        setUserId(0);
      }
      window.location.reload();
    }
  };

  const applyCouponCode = async (sessionCartDetails) => {
    const localData = {
      package_id: selectedSessionPackage.id ?? 0,
      ...(couponFound && { coupon_code_id: matchingCoupon.id ?? 0 }),
    };
    try {
      const res = await open_api_with_user_token(bearerLocalToken).patch(
        `/session/card/${sessionCartDetails.id}/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return res?.data || [];
      }
    } catch (error) {}
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  const getSessionPayData = async () => {
    const sessionCartDetails = await getSessionCartDetails();
    const appliedCoupon = await applyCouponCode(sessionCartDetails);
    const localData = {
      session_purchase_id: sessionCartDetails.id ?? 0,
      amount: appliedCoupon.total_amt,
    };
    try {
      const res = await open_api_with_user_token(bearerLocalToken).post(
        `/payment/pay/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return {
          ...res.data,
          sessionCartDetailsId: sessionCartDetails.id,
          appliedCoupon: appliedCoupon,
        };
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const getSessionCartDetails = async () => {
    const localData = {
      package_id: selectedPackage ?? 0,
      currency: isUSD,
      purchasedFrom: 1,
    };

    try {
      const res = await open_api_with_user_token(bearerLocalToken).post(
        `/session/card/`,
        localData
      );
      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setSessionCartId(res?.data?.id);
        return res?.data;
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleValidateOTP = async () => {
    setUseEmail(false);
    setUsePhone(true);
    await validateOTP(
      otp,
      phoneNumber,
      setOtpValidated,
      setVerified,
      setUserId,
      setBearerToken,
      setShowAlert,
      handleAddAlert,
      setfirstName,
      setlastName,
      setEmail,
      setUserName,
      setPhoneNumber
    );
  };

  const sumbitUserDetails = async () => {
    let registerAsId, title;
    switch (profileType.toLowerCase()) {
      case "mom":
        title = "Mrs";
        break;
      case "dad":
        title = "Mr";
        break;
      case "grand pa":
        title = "Mr";
        break;
      case "grand ma":
        title = "Mrs";
        break;
      default:
        title = "Mr/Mrs";
    }

    const userDetails = {
      id: userLocalId,
      phone: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      email: email,
      register_as_id: profileTypeId,
      title: title,
    };
    try {
      const res = await open_api_with_user_token(bearerLocalToken).patch(
        `/profiles/update_profile/`,
        userDetails
      );

      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        localStorage.setItem("userName", firstName + " " + lastName);
        setUserName(firstName + " " + lastName);
        return true;
      } else if (res?.status === 400) {
        if (res?.data?.email != null) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.email, "error", true);
          setIsLoading(false);
          return false;
        }
      }
      return false;
    } catch (err) {
      console.error("Error in sumbitUserDetails:", err);
      return false;
    }
  };

  const handleOTPInputChange = (e, index) => {
    handleOTPChange(e, index, otp, setOtp, inputRefs);
  };

  const handleOTPKeyDown = (e, index) => {
    handleKeyDown(e, index, otp, inputRefs);
  };

  const initiateRazorpayPayment = async (
    payData,
    sessionCartDetailsId,
    appliedCoupon
  ) => {
    const chart_id = payData.id;
    setMessage("Confirming your order, please don't close the screen...");
    const razorpayKey = isProduction
      ? "rzp_live_0MAqWVbokRW0Uu"
      : "rzp_test_41tt7Y8uKXr9rl";
    const options = {
      key: razorpayKey,
      amount: payData.amount * 100, // Razorpay works with paise, so multiply by 100
      currency: isUSD ? "USD" : "INR",
      name: "myburgo",
      description: "Payment for Workshop",
      order_id: payData.order_id,
      timeout: 300,
      handler: (response) => {
        //console.log("Payment successful:", response);
        handlePaymentSuccess(payData.id, sessionCartDetailsId, false);
      },
      prefill: {
        name: `${appliedCoupon.user_detail.first_name} ${appliedCoupon.user_detail.last_name}`,
        email: appliedCoupon.user_detail.email,
        contact: appliedCoupon.user_detail.phone,
      },
      notes: {
        address: "Customer Address",
      },
      theme: {
        color: "#7A53FF",
      },
      modal: {
        ondismiss: function () {
          //console.log("Checkout form closed");
          handlePaymentFailure(
            { error: "Payment window closed without completing the payment." },
            payData.id
          );
        },
      },
    };
    const rzp = new window.Razorpay(options);

    rzp.on("payment.failed", ({ response }) => {
      console.error("Payment failed:", response.error);
    });

    rzp.open();
  };

  const [alerts, setAlerts] = useState([]);

  const fetchWorkshopDetailsAndPay = async () => {
    setIsLoading(true);
    try {
      if (isUserInfoExists) {
        const payData = await getSessionPayData();
        const { appliedCoupon, sessionCartDetailsId, ...resData } = payData;
        if (payData.amount > 0) {
          await initiateRazorpayPayment(
            resData,
            sessionCartDetailsId,
            appliedCoupon
          );
        } else {
          setMessage("Confirming your order, please don't close the screen...");
          handlePaymentSuccess(payData.id, sessionCartDetailsId, true);
        }
      }
    } catch (error) {
      // Handle error appropriately
    }
  };

  const handlePaymentSuccess = async (
    cart_id,
    sessionCartDetailsId,
    is_bypassed
  ) => {
    try {
      const localData = {
        is_bypassed: is_bypassed,
      };
      await new Promise((resolve) => setTimeout(resolve, 2000));
      const res = await open_api_with_user_token(bearerLocalToken).patch(
        `/payment/pay/${cart_id}/`,
        localData
      );
      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        const sessionData = sessionStorage.getItem("expertSession");
        if (sessionData) {
          const expertSessionData = JSON.parse(sessionData);
          postExpertUserPurchase(
            expertSessionData.expertslug,
            bearerLocalToken,
            null,
            sessionCartDetailsId
          );
        }
        postBookedSlot(sessionCartDetailsId);
        setPaymentSuccessful(true);
        setIsLoading(false);
        setDialogProps({
          status: "success",
          statusText: "Thank you",
          statusDescription:
            "You have successfully purchased an expert Session. We will be sending you an email confirmation shortly.",
          statusIcon: successIcon,
          backgroundColor: "#FFFF",
        });
        setConfirmationDialogOpen(true);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handlePaymentFailure = async (response, cart_id) => {
    try {
      const res = await open_api_with_user_token(bearerLocalToken).patch(
        `/payment/pay/${cart_id}/`
      );
      console.error("Payment failed:", response);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        setPaymentSuccessful(true);
        setDialogProps({
          status: "failure",
          statusText: "Payment Failed",
          statusDescription:
            "There was an issue with the payment. Please try again or contact support at hello@myburgo.com if the problem persists.",
          statusIcon: FailedIcon,
          backgroundColor: "#FFFF",
        });
        setIsLoading(false);
        setConfirmationDialogOpen(true);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const postBookedSlot = async (sessionCartDetailsId) => {
    const slotId = selectedSlot.id ?? 0;
    // console.log("userId = ", userLocalId);
    // console.log("slotid = ", slotId);
    // console.log("packageId = ", selectedPackage);
    // console.log("workshopcharid =", sessionCartDetailsId);
    const localData = {
      purchase_id: sessionCartDetailsId,
      user_id: userLocalId,
      slot_id: slotId,
      package_id: selectedPackage,
      status: 1,
    };

    try {
      const res = await open_api_with_user_token(bearerLocalToken).post(
        `/session/booked_slot/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return res?.data;
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleCloseConfirmationDialog = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setConfirmationDialogOpen(false);
      resetAllStates();
    }
  };

  const resetAllStates = () => {
    setConfirmationDialogOpen(false);
    setUseEmail(false);
    setUsePhone(false);
    setOtpRequested(false);
    setContactValue("");
    setPaymentSuccessful(false);
    setVerified(false);
    setIsLoading(false);
    setEmail("");
    setPhoneNumber("");
    setfirstName("");
    setPhoneNumberError(false);
    setEmailError(false);
    setfirstNameError(false);
    onClose();
    setOtp(Array(4).fill(""));
    setCouponFound(false);
    setCoupon([]);
    setDiscountAmount(0);
    setBearerToken("");
    setUserId(0);
    setOtpValidated(false);
    setCountryCode("in");
    setSelectedDate(null);
    setSelectedPackage(null);
    setSelectedSessionPackage([]);
    setSelectedTimeSlot(null);
    setShowPaymentSection(false);
    setShowDateAvialbilitySection(false);
    setChangePackage(false);
    setSelectedSlot(null);
    setShowAlert(false);
    setProfileTypes([]);
    setProfileType("");
    setProfileTypeError(false);
    setMessage("");
    if (!isAuthenticated) {
      setEmail("");
      setPhoneNumber("");
      setfirstName("");
      setlastName("");
      setBearerToken("");
      setUserId(0);
    }
    window.location.reload();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeSlotChange = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
  };

  const handleDurationChange = (duration) => {
    setSelectedDuration(duration);
  };

  const handleSelectedSlotChange = (selectedSlot) => {
    setSelectedSlot(selectedSlot);
  };

  const handleIsUserDataRequired = (userDataRequired) => {
    setIsUserDataRequired(userDataRequired);
  };

  const handleProceedToPayment = () => {
    if (!selectedTimeSlot || !selectedDate) {
      if (!selectedDate) {
        setShowAlert(true);
        handleAddAlert(
          "Input Error",
          "Please select a date before proceeding.",
          "error",
          true
        );
        return;
      } else {
        setShowAlert(true);
        handleAddAlert(
          "Input Error",
          "Please select a time before proceeding.",
          "error",
          true
        );
        return;
      }
    }
    setShowPaymentSection(true);
  };

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedSessionPackage, setSelectedSessionPackage] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedPackageDuration, setSelectionPackageDuration] = useState(null);
  const [showPaymentSection, setShowPaymentSection] = useState(false);
  const [showDateAvialabiltySection, setShowDateAvialbilitySection] =
    useState(false);
  const [changePackage, setChangePackage] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);

  const handlePackageSelect = (id, duration) => {
    setSelectedPackage(id);
    setSelectionPackageDuration(duration);
  };

  const handleProceedToPay = () => {
    const selectedPackageData = packages.find(
      (pkg) => pkg.id === selectedPackage
    );

    if (!selectedPackageData) {
      setShowAlert(true);
      handleAddAlert(
        "Action Required",
        "Please select a package before proceeding",
        "error",
        true
      );
      return;
    }

    setSelectedSessionPackage(selectedPackageData);
    //console.log(selectedPackageData);

    if (changePackage) {
      setShowDateAvialbilitySection(false);
      setShowPaymentSection(true);
    } else {
      setShowDateAvialbilitySection(true);
    }
  };

  const handleChangePackage = () => {
    // Reset package state
    setSelectedPackage(null);
    setShowPaymentSection(false);
    setChangePackage(true);
    setShowDateAvialbilitySection(false);
    // Additional logic if needed
  };

  const handleChangeSlot = () => {
    setSelectedDate(null);
    setSelectedTimeSlot(null);
    setShowPaymentSection(false);
    setChangePackage(false);
    setShowDateAvialbilitySection(true);
  };

  const formatTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date();
    date.setHours(hours, minutes, seconds);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    setUseEmail(true);
    setUsePhone(false);
    validateGoogleLoginWithToken(
      credentialResponse.credential,
      setOtpValidated,
      setVerified,
      setUserId,
      setBearerToken,
      setShowAlert,
      handleAddAlert,
      setEmail,
      setPhoneNumber,
      setfirstName,
      setlastName,
      setIsAuthenticated,
      setUserName,
      setIsUserInfoExists,
      true
    );
  };

  const handlePayEnable = (enable) => {
    setEnablePayButton(enable);
  };

  const handleUserInfoExists = (exists) => {
    setIsUserInfoExists(exists);
  };

  const handleBearerToken = (token) => {
    setBearerToken(token);
  };

  const handleLoginError = () => {
    console.error("Google Login Failed");
  };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: handleGoogleLoginSuccess,
  //   onError: handleLoginError,
  // });

  return (
    <>
      <MuiDialog
        open={open && !isPaymentSuccessful}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        fullScreen={fullScreen}
        sx={{ "& .MuiDialog-paper": { maxWidth: "500px" } }}
      >
        {isLoading && <LoadingOverlay message={message} />}
        <DialogContent sx={{ padding: 0, position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContainer>
            {!showPaymentSection ? (
              <LeftContainer>
                <Header>Book an expert session</Header>
                <ExpertInfo>
                  <ExpertImage
                    src={expertDetails?.image}
                    alt={expertDetails?.slug}
                  />
                  <ExpertDetails>
                    <ExpertName>
                      {expertDetails?.user?.first_name}{" "}
                      {expertDetails?.user?.last_name}
                    </ExpertName>
                    <div className="divider"></div>
                    <ExpertDescription>
                      {expertDetails?.specialist_tagline}
                    </ExpertDescription>
                  </ExpertDetails>
                </ExpertInfo>
                {!showDateAvialabiltySection ? (
                  <PackageHeader>Please select a package</PackageHeader>
                ) : (
                  <PackageHeader>When should we meet?</PackageHeader>
                )}
                {!showDateAvialabiltySection ? (
                  <>
                    {packages && packages.length > 0 ? (
                      <>
                        <PackageContainer>
                          {packages.map((pkg) => (
                            <PackageCard
                              key={pkg.id}
                              onClick={() =>
                                handlePackageSelect(pkg.id, pkg.duration)
                              }
                              className={
                                selectedPackage === pkg.id ? "selected" : ""
                              }
                            >
                              <PackageTitle>{pkg.title}</PackageTitle>
                              <PackagePrice>
                                {priceSymbol}
                                {countryCode === "in"
                                  ? pkg.selling_price
                                  : pkg.selling_price_usd}
                              </PackagePrice>
                            </PackageCard>
                          ))}
                        </PackageContainer>
                        <PayButton onClick={handleProceedToPay}>
                          Proceed to Pay
                        </PayButton>
                      </>
                    ) : (
                      <NoPackagesContainer>
                        <NoPackagesImage src={emptyBox} alt="No Packages" />
                        <NoPackagesText>
                          All slots are booked for this month
                        </NoPackagesText>
                      </NoPackagesContainer>
                    )}
                  </>
                ) : (
                  <DateAvailability
                    expertId={expertDetails.id}
                    onDateChange={handleDateChange}
                    onTimeSlotChange={handleTimeSlotChange}
                    onProceedToPayment={handleProceedToPayment}
                    onSelectedSlot={handleSelectedSlotChange}
                    isUserDataRequired={handleIsUserDataRequired}
                    onDurationChange={handleDurationChange}
                    packageDuration={selectedPackageDuration}
                  />
                )}
              </LeftContainer>
            ) : (
              <RightSection>
                <h1>Payment details</h1>
                <h2>
                  Complete your purchase by providing your payment details.
                </h2>
                <LoginCard
                  setEnablePayButton={handlePayEnable}
                  bypass={true}
                  updateIsUserInfoExists={handleUserInfoExists}
                  updateBearerToken={handleBearerToken}
                />

                {otpValidated || isAuthenticated ? (
                  <Card>
                    <h3>Have a coupon code? Enter here</h3>
                    <CouponContainer>
                      <div className="couponHandler">
                        <CouponInput
                          type="text"
                          placeholder="Enter Coupon Code"
                          value={coupon}
                          onChange={(event) =>
                            handleCouponChange(event, setCoupon)
                          }
                        />
                        <ApplyButton onClick={handleApplyCoupon}>
                          Apply
                        </ApplyButton>
                      </div>
                      {couponFound && (
                        <CongratulationsBanner
                          priceSymbol={priceSymbol}
                          discount={discountAmount}
                        />
                      )}
                    </CouponContainer>
                  </Card>
                ) : (
                  <></>
                )}
                <Card>
                  <h3>You have selected the below package</h3>
                  <InnerCardContainer>
                    <InnerCardFlex>
                      <InnerCard>
                        <p style={{ fontWeight: 700 }}>
                          {selectedSessionPackage.title}
                        </p>
                        <p style={{ fontWeight: 500 }}>
                          {priceSymbol}
                          {countryCode === "in"
                            ? selectedSessionPackage.selling_price
                            : selectedSessionPackage.selling_price_usd}
                        </p>
                      </InnerCard>
                      <ChangeLink onClick={handleChangePackage}>
                        Change Package
                      </ChangeLink>
                    </InnerCardFlex>
                    <InnerCardFlex>
                      <InnerCard>
                        <p style={{ fontWeight: 700 }}>
                          {formatTime(selectedTimeSlot)} ({selectedDuration}{" "}
                          mins)
                        </p>
                        <p style={{ fontWeight: 500 }}>
                          {formatDate(new Date(selectedDate))}
                        </p>
                      </InnerCard>
                      <ChangeLink onClick={handleChangeSlot}>
                        Change Slot
                      </ChangeLink>
                    </InnerCardFlex>
                  </InnerCardContainer>
                </Card>
                <ServiceCard>
                  <div className="service-details">
                    <div className="service-info">
                      <h4>Service</h4>
                      <p>{selectedSessionPackage.title} package</p>
                      {couponFound && <p>Coupon discount</p>}
                    </div>
                    <div className="service-price">
                      <div style={{ flexDirection: "column" }}>
                        <div
                          style={{
                            paddingBottom: couponFound ? "10px" : "0",
                          }}
                        >
                          <span className="original-price">
                            {priceSymbol}
                            {countryCode === "in"
                              ? selectedSessionPackage.mrp
                              : selectedSessionPackage.mrp_usd}
                          </span>
                          <span className="discounted-price">
                            {priceSymbol}
                            {countryCode === "in"
                              ? selectedSessionPackage.selling_price
                              : selectedSessionPackage.selling_price_usd}
                          </span>
                        </div>
                        {couponFound && (
                          <span
                            className="discounted-price"
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            - {priceSymbol}
                            {discountAmount}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <AmountCard>
                    <div className="amount-left">
                      <h4>Amount to be paid</h4>
                    </div>
                    <div className="amount-right">
                      <span className="discounted-price">
                        {priceSymbol}
                        {couponFound
                          ? newSessionPackagePrice
                          : countryCode === "in"
                            ? selectedSessionPackage.selling_price
                            : selectedSessionPackage.selling_price_usd}
                      </span>
                    </div>
                  </AmountCard>
                </ServiceCard>

                {isAuthenticated ? (
                  <PayButton
                    className="payButton"
                    onClick={fetchWorkshopDetailsAndPay}
                    disabled={!enablePayButton}
                  >
                    Proceed to Pay
                  </PayButton>
                ) : (
                  <></>
                )}
              </RightSection>
            )}
            {showAlert &&
              alerts.map((alert) => (
                <CustomAlert
                  key={alert.id}
                  title={alert.title}
                  message={alert.message}
                  onClose={() => handleRemoveAlert(alert.id)}
                  autoClose={alert.autoClose}
                  type={alert.type}
                />
              ))}
          </DialogContainer>
        </DialogContent>
      </MuiDialog>

      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          onClose={handleCloseConfirmationDialog}
          image={expertDetails?.image}
          title={expertDetails?.user?.first_name}
          description={expertDetails?.about}
          {...dialogProps}
          redirect={
            dialogProps.status === "success" && isUserDataRequired
              ? `/expert/${expertDetails?.slug}/bookings/${sessionCartId}/session-data`
              : `/profile/sessions/upcoming`
          }
          countdownDuration={5}
        />
      )}
    </>
  );
};

export default BookSessionDialog;
