import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "@mui/material";
import {
  Card,
  Button,
  OtpContainer,
  OtpInputContainer,
  ResendOtpLink,
  SuccessMessage,
  Label,
  InputField,
  ErrorText,
  Select,
  PayButton,
  LoginButton,
} from "../Dialog/PaymentDialogStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTheme } from "@mui/material/styles";
import { open_api_with_user_token } from "../../Utils/network";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  fetchCountryCode,
  validatePhoneNumber,
  validateEmail,
  handleOTPChange,
  handleKeyDown,
  handlePhoneNumberChange,
  handleEmailChange,
  requestOTP,
  validateOTP,
  addAlert,
  removeAlert,
  fetchProfileTypes,
  checkUserInfoExists,
  fetchUserDetails,
  validateGoogleLoginWithToken,
} from "../Dialog/helper";
import useAuth from "../Hook/useAuth";
import { GoogleLogin } from "@react-oauth/google";
import CustomAlert from "../AlertDialog/CustomAlert";
import { useNavigate } from "react-router-dom";
import LoadingOverlay from "../Dialog/LoadingOverlay";
import UserProfileForm from "./UserProfileForm";

const LoginCard = ({
  setEnablePayButton,
  bypass,
  updateIsUserInfoExists,
  updateBearerToken,
}) => {
  const [useEmail, setUseEmail] = useState(false);
  const [usePhone, setUsePhone] = useState(false);
  const [isVerified, setVerified] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [otpValidated, setOtpValidated] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [countryCode, setCountryCode] = useState("in");
  const inputRefs = useRef([]);
  const [bearerLocalToken, setBearerToken] = useState("");
  const [userLocalId, setUserId] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [resentOTP, setResentOTP] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [profileTypeId, setProfileTypeId] = useState(0);
  const [profileType, setProfileType] = useState("");
  const [profileTypeError, setProfileTypeError] = useState(false);
  const [profileTypes, setProfileTypes] = useState([]);
  const [userLocalName, setUserName] = useState("");
  const { isAuthenticated, userName, userId, bearerToken, setIsAuthenticated } =
    useAuth();
  const [isUserInfoExists, setIsUserInfoExists] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [isHideLoginButton, setIsHideLoginButton] = useState(false);

  useEffect(() => {}, [isAuthenticated]);
  useEffect(() => {
    const updateUserDetails = async () => {
      if (isAuthenticated && bearerToken && userId) {
        // Ensure all necessary data is available
        const sessionUseEmail = sessionStorage.getItem("useEmail");
        const sessionUsePhone = sessionStorage.getItem("usePhone");
        setUseEmail(sessionUseEmail === "true");
        setUsePhone(sessionUsePhone === "true");
        setBearerToken(bearerToken);
        setUserName(userName);
        setUserId(userId);
        const userInfo = await fetchUserDetails(bearerToken, userId);
        setPhoneNumber(userInfo?.phone);
        setEmail(userInfo?.email);
        setfirstName(userInfo?.first_name);
        setLastName(userInfo?.last_name);
        setIsUserInfoExists(
          checkUserInfoExists(
            userInfo?.first_name,
            userInfo?.last_name,
            userInfo?.email,
            userInfo?.phone,
            sessionUseEmail
          )
        );
        console.log("user info exists: ", isUserInfoExists);
        console.log("ishidelogin: ", isHideLoginButton);
        console.log("isauthenticated:", isAuthenticated);
      }
    };
    updateUserDetails();
  }, [isAuthenticated, bearerToken, userId, userName]);

  const isUSD = countryCode === "in" ? "INR" : "USD";

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && resentOTP) {
      setResentOTP(false);
    }
  }, [countdown, resentOTP]);

  useEffect(() => {
    const fetchTypes = async () => {
      var accessToken = bearerLocalToken;
      if (accessToken === "") {
        accessToken = localStorage.getItem("bearerToken");
      }
      updateBearerToken(accessToken);
      await fetchProfileTypes(accessToken, setProfileTypes);
    };
    fetchTypes();
  }, [bearerLocalToken]);

  useEffect(() => {
    const getCountryCode = async () => {
      const countryCode = await fetchCountryCode();
      setCountryCode(countryCode);
    };
    getCountryCode();
  }, [countryCode]);

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  const handleSubmitOTP = async () => {
    await handleValidateOTP();
  };

  const handleRequestOTP = () => {
    setEmailError(false);
    setPhoneNumberError(false);
    if (
      useEmail
        ? validateEmail(email, setEmailError, setShowAlert, handleAddAlert)
        : validatePhoneNumber(
            phoneNumber,
            setPhoneNumberError,
            setShowAlert,
            handleAddAlert
          )
    ) {
      requestOTP(phoneNumber, setOtpRequested, setShowAlert, handleAddAlert);
      // Request OTP logic here
    } else {
    }
  };

  const handleResendOTP = () => {
    handleRequestOTP();
    setResentOTP(true);
    setCountdown(30);
  };

  const handlePhoneInputChange = (value, data) => {
    handlePhoneNumberChange(
      value,
      data,
      setCountryCode,
      setPhoneNumber,
      setPhoneNumberError
    );
  };

  const handleEmailInputChange = (e) => {
    handleEmailChange(e, setEmail, setEmailError);
  };

  const handlePhoneNumberInputChange = (e) => {
    const value = e.target.value;
    const phoneRegex = /^[0-9]*$/;
    if (value === "" || phoneRegex.test(value)) {
      setPhoneNumber(value);
      setPhoneNumberError(value.trim() === "");
    }
  };

  const handlefirstNameChange = (e) => {
    const value = e.target.value;
    setfirstName(value);

    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(value)) {
      setfirstNameError(value.trim() === "");
    } else {
      setfirstNameError(true);
    }
  };

  const handlelastNameChange = (e) => {
    const value = e.target.value;
    // Allow input updates, even with numbers
    setLastName(value);

    // Validate the input value
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(value)) {
      setLastNameError(value.trim() === ""); // Check for empty field
    } else {
      setLastNameError(true); // Set error if it contains invalid characters
    }
  };

  const handleOTPInputChange = (e, index) => {
    handleOTPChange(e, index, otp, setOtp, inputRefs);
  };

  const handleOTPKeyDown = (e, index) => {
    handleKeyDown(e, index, otp, inputRefs);
  };

  const handleProfileTypeChange = (e) => {
    const selectedId = e.target.value;
    const selectedType = profileTypes.find(
      (type) => type.id.toString() === selectedId
    );
    if (selectedType) {
      setProfileType(selectedType.title);
      setProfileTypeId(selectedType.id);
    } else {
      setProfileType("");
    }
    setProfileTypeError(false);
  };

  const handleValidateOTP = async () => {
    setUseEmail(false);
    setUsePhone(true);
    await validateOTP(
      otp,
      phoneNumber,
      setOtpValidated,
      setVerified,
      setUserId,
      setBearerToken,
      setShowAlert,
      handleAddAlert,
      setfirstName,
      setLastName,
      setEmail,
      setPhoneNumber,
      setIsAuthenticated,
      setIsUserInfoExists,
      setEnablePayButton,
      updateIsUserInfoExists
    );
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    setUseEmail(true);
    setUsePhone(false);
    validateGoogleLoginWithToken(
      credentialResponse.credential,
      setOtpValidated,
      setVerified,
      setUserId,
      setBearerToken,
      setShowAlert,
      handleAddAlert,
      setEmail,
      setPhoneNumber,
      setfirstName,
      setLastName,
      setUserName,
      setIsAuthenticated,
      setIsUserInfoExists,
      true,
      setEnablePayButton,
      updateIsUserInfoExists
    );
  };

  const handleGoogleLoginError = () => {
    setShowAlert(true);
    handleAddAlert(
      "Login Error",
      "Failed to login with google. Please try again",
      "error",
      true
    );
  };

  const validateForm = () => {
    let valid = true; // Initialize valid flag

    // Regex patterns
    const nameRegex = /^[A-Za-z\s]+$/;
    const phoneRegex = /^[0-9]*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!firstName || !nameRegex.test(firstName)) {
      setfirstNameError(true);
      valid = false;
    } else {
      setfirstNameError(false);
    }

    if (!lastName || !nameRegex.test(lastName)) {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }

    if (useEmail) {
      console.log(phoneNumber);
      if (!phoneNumber || !phoneRegex.test(phoneNumber)) {
        setPhoneNumberError(true);
        valid = false;
      } else {
        setPhoneNumberError(false);
      }
    } else {
      if (!phoneNumber || !phoneRegex.test(phoneNumber)) {
        setPhoneNumberError(true);
        valid = false;
      } else {
        setPhoneNumberError(false);
      }

      if (!email || !emailRegex.test(email)) {
        setEmailError(true);
        valid = false;
      } else {
        setEmailError(false);
      }
    }

    if (!profileType) {
      setProfileTypeError(true);
      valid = false;
    } else {
      setProfileTypeError(false);
    }

    return valid;
  };

  const fetchWorkshopDetailsAndPay = async () => {
    let valid = true;
    if (!isUserInfoExists) {
      valid = validateForm();
    }

    if (valid) {
      setIsLoading(true);
      try {
        let userDetailsUpdated;
        if (!isUserInfoExists) {
          const result = await sumbitUserDetails();
          userDetailsUpdated = result.success;

          if (result.alertShown) {
            return;
          }
        }

        if (userDetailsUpdated || isUserInfoExists) {
          console.log("bypass", bypass);
          if (bypass) {
            setIsUserInfoExists(true);
            updateIsUserInfoExists(true);
            setEnablePayButton(true);
            setIsHideLoginButton(true);
            setIsLoading(false);
          } else {
            await new Promise((resolve) => setTimeout(resolve, 500));
            navigate("/");
            window.location.reload();
          }
        } else {
          setShowAlert(true);
          handleAddAlert(
            "Input Error",
            "There was an issue while submitting the form",
            "error",
            true
          );
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error in flow:", error);
        setIsLoading(false);
      }
    } else {
      setShowAlert(true);
      handleAddAlert(
        "Input Error",
        "Please fill in all mandatory fields before proceeding",
        "error",
        true
      );
      setIsLoading(false);
    }
  };

  const sumbitUserDetails = async () => {
    let registerAsId, title;
    switch (profileType.toLowerCase()) {
      case "mom":
        title = "Mrs";
        break;
      case "dad":
        title = "Mr";
        break;
      case "grand pa":
        title = "Mr";
        break;
      case "grand ma":
        title = "Mrs";
        break;
      default:
        title = "Mr/Mrs";
    }

    const userDetails = {
      id: userLocalId,
      phone: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      email: email,
      register_as_id: profileTypeId,
      title: title,
    };
    try {
      const res = await open_api_with_user_token(bearerLocalToken).patch(
        `/profiles/update_profile/`,
        userDetails
      );

      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        localStorage.setItem("userName", firstName + " " + lastName);
        setUserName(firstName + " " + lastName);
        return { success: true, alertShown: false };
      } else if (res?.status === 400) {
        if (res?.data?.email) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.email, "error", true);
          setIsLoading(false);
          return { success: false, alertShown: true };
        } else if (res?.data?.phone) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.phone, "error", true);
          setIsLoading(false);
          return { success: false, alertShown: true };
        } else {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data, "error", true);
          setIsLoading(false);
          return { success: false, alertShown: true };
        }
      }
    } catch (err) {
      console.error("Error in sumbitUserDetails:", err);
      return { success: false, alertShown: false };
    }
    return { success: false, alertShown: false };
  };

  return (
    <>
      {isLoading && <LoadingOverlay message={message} />}
      {!isAuthenticated ? (
        <Card>
          {otpValidated ? (
            <>
              {!isUserInfoExists ? (
                <UserProfileForm
                  useEmail={useEmail}
                  email={email}
                  phoneNumber={phoneNumber}
                  usePhone={usePhone}
                  firstName={firstName}
                  lastName={lastName}
                  profileType={profileType}
                  profileTypes={profileTypes}
                  firstNameError={firstNameError}
                  lastNameError={lastNameError}
                  phoneNumberError={phoneNumberError}
                  emailError={emailError}
                  profileTypeError={profileTypeError}
                  handlefirstNameChange={handlefirstNameChange}
                  handlelastNameChange={handlelastNameChange}
                  handlePhoneNumberInputChange={handlePhoneNumberInputChange}
                  handleEmailInputChange={handleEmailInputChange}
                  handleProfileTypeChange={handleProfileTypeChange}
                />
              ) : (
                <>
                  <SuccessMessage>
                    <CheckCircleIcon color="success" />
                    <h3>You are logged in as {userLocalName}</h3>
                  </SuccessMessage>
                </>
              )}
            </>
          ) : otpRequested ? (
            <>
              <h3>A 4-digit code is sent to {`+91 ${phoneNumber}`}</h3>
              <OtpInputContainer>
                {Array.from({ length: 4 }).map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={otp[index]}
                    onChange={(e) => handleOTPInputChange(e, index)}
                    onKeyDown={(e) => handleOTPKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </OtpInputContainer>
              <Button onClick={handleSubmitOTP}>Submit</Button>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                {resentOTP ? (
                  <div>
                    <p>Resend OTP in {countdown} seconds</p>
                  </div>
                ) : (
                  <div>
                    <ResendOtpLink onClick={handleResendOTP}>
                      Resend OTP
                    </ResendOtpLink>
                    <p style={{ marginTop: "8px", textAlign: "center" }}>
                      Didn't receive a code? Click on Resend to receive OTP
                    </p>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <h3>Verify using Phone Number</h3>
              <OtpContainer
                style={{
                  borderColor: phoneNumberError ? "red" : "#ccc",
                }}
              >
                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onChange={handlePhoneInputChange}
                  placeholder="Enter your phone number"
                  onlyCountries={["in"]}
                  disableDropdown
                  disableCountryCode
                  inputStyle={{
                    border: "none",
                    outline: "none",
                    padding: "8px 8px 8px 44px",
                    flex: "1",
                    borderRadius: "0 8px 8px 0",
                    overflow: "auto",
                    width: "100%",
                  }}
                />
              </OtpContainer>

              {phoneNumberError && (
                <ErrorText>Please enter a valid phone number</ErrorText>
              )}

              <Button onClick={handleRequestOTP}>Request OTP</Button>

              {/* Centered "OR" Divider */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  margin: "20px 0",
                }}
              >
                <hr style={{ flex: 1 }} />
                <span style={{ padding: "0 10px", color: "#666" }}>OR</span>
                <hr style={{ flex: 1 }} />
              </div>

              {/* <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                         
                          <button
                            onClick={() => googleLogin()} // Trigger login on button click
                            style={{
                              backgroundColor: "transparent", // Or any custom color
                              border: "none",
                              cursor: "pointer",
                              padding: "0", // Ensure no extra padding
                            }}
                          >
                            
                            <img
                              src={googleLoginIcon} // Path to your SVG
                              alt="Google Login"
                              style={{ width: "200px", height: "50px" }} // Adjust size as per your needs
                            />
                          </button>
                        </div> */}
              {/* Google Login Button */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <GoogleLogin
                  onSuccess={handleGoogleLoginSuccess}
                  onError={handleGoogleLoginError}
                />
              </div>
            </>
          )}
        </Card>
      ) : (
        <Card>
          {!isUserInfoExists ? (
            <UserProfileForm
              useEmail={useEmail}
              email={email}
              phoneNumber={phoneNumber}
              usePhone={usePhone}
              firstName={firstName}
              lastName={lastName}
              profileType={profileType}
              profileTypes={profileTypes}
              firstNameError={firstNameError}
              lastNameError={lastNameError}
              phoneNumberError={phoneNumberError}
              emailError={emailError}
              profileTypeError={profileTypeError}
              handlefirstNameChange={handlefirstNameChange}
              handlelastNameChange={handlelastNameChange}
              handlePhoneNumberInputChange={handlePhoneNumberInputChange}
              handleEmailInputChange={handleEmailInputChange}
              handleProfileTypeChange={handleProfileTypeChange}
            />
          ) : (
            <SuccessMessage>
              <CheckCircleIcon color="success" />
              <h3>You are logged in as {userLocalName}</h3>
            </SuccessMessage>
          )}

          {bypass ? (
            <>
              {(isAuthenticated && isUserInfoExists) || isHideLoginButton ? (
                <></>
              ) : (
                <LoginButton
                  className="payButton"
                  onClick={fetchWorkshopDetailsAndPay}
                  bypass={bypass}
                >
                  Login
                </LoginButton>
              )}
            </>
          ) : (
            <>
              <LoginButton
                className="payButton"
                onClick={fetchWorkshopDetailsAndPay}
                bypass={bypass}
              >
                {isUserInfoExists ? "Continue" : "Login"}
              </LoginButton>
            </>
          )}
        </Card>
      )}
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </>
  );
};

export default LoginCard;
