// src/Utils/helper.js
import axios from "axios";
import {
  open_api,
  open_api_with_user_token,
  open_api_without_auth,
} from "../../Utils/network";

export const fetchCountryCode = async () => {
  try {
    const response = await axios.get("https://ipapi.co/json/");
    return response.data.country_code.toLowerCase();
  } catch (error) {
    console.error("Error fetching the country code", error);
    return "in"; // Default country code in case of error
  }
};

export const validatePhoneNumber = (
  number,
  setPhoneNumberError,
  setShowAlert,
  addAlert
) => {
  if (number.length >= 10) {
    //console.log("phone verified");
    return true;
  } else {
    setPhoneNumberError(true);
    setShowAlert(true);
    addAlert(
      "Input Error",
      "Please enter a valid phone number before proceeding",
      "error",
      true
    );
    return false;
  }
};

export const validateEmail = (email, setEmailError, setShowAlert, addAlert) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(email)) {
    return true;
  } else {
    setShowAlert(true);
    addAlert(
      "Input Error",
      "Please enter a valid email before proceeding",
      "error",
      true
    );
    setEmailError(true);
    return false;
  }
};

export const handleOTPChange = (e, index, otp, setOtp, inputRefs) => {
  const { value } = e.target;
  if (/^[0-9]$/.test(value) || value === "") {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < 3) {
      inputRefs.current[index + 1].focus();
    }
  }
};

export const handleKeyDown = (e, index, otp, inputRefs) => {
  if (e.key === "Backspace" && otp[index] === "" && index > 0) {
    inputRefs.current[index - 1].focus();
  }
};

export const handlePhoneNumberChange = (
  value,
  data,
  setCountryCode,
  setPhoneNumber,
  setPhoneNumberError
) => {
  const countryCode = data.dialCode; // Extract the country code
  //setCountryCode(countryCode);
  setPhoneNumber(value);
  if (value) {
    setPhoneNumberError(false);
  }
};

export const handleEmailChange = (e, setEmail, setEmailError) => {
  if (e.target.name === "email") {
    setEmailError(false);
  }
  setEmail(e.target.value);
};

export const verifyCoupon = async (
  coupon,
  workshop_id,
  sessionpackage_id,
  bearerToken
) => {
  var localData = {};
  if (workshop_id != null) {
    localData = {
      workshop_id: workshop_id ?? 0,
      coupon_code: coupon,
    };
  } else {
    localData = {
      sessionpackage_id: sessionpackage_id ?? 0,
      coupon_code: coupon,
    };
  }

  //console.log(localData);
  try {
    const res = await open_api_with_user_token(bearerToken).post(
      `/workshop/coupon/verify/`,
      localData
    );
    //console.log(res);
    if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
      return res?.data;
    }
  } catch (error) {
    //console.log("failed to get coupon list");
  }
  return [];
};

export const applyCoupon = async (
  coupon,
  workshop_id,
  sessionpackage_id,
  bearerToken,
  sellingPrice,
  setShowAlert,
  addAlert,
  setCouponFound,
  setMatchingCoupon,
  setNewSellingPrice,
  setDiscountAmount,
  setCoupon,
  isUSD
) => {
  if (!coupon) {
    setShowAlert(true);
    addAlert("Input Error", "Please enter a valid coupon", "error", true);
    return;
  }

  const matchingCoupon = await verifyCoupon(
    coupon,
    workshop_id,
    sessionpackage_id,
    bearerToken
  );
  if (Array.isArray(matchingCoupon) && matchingCoupon.length === 0) {
    setShowAlert(true);
    addAlert("Input Error", "Invalid Coupon code", "error", true);
    return;
  }

  if (matchingCoupon) {
    const currentDate = new Date();
    const toDate = new Date(matchingCoupon.to_date);

    if (toDate >= currentDate) {
      setCouponFound(true);
      setMatchingCoupon(matchingCoupon);
      // Calculate discount
      let discount = 0;
      if (matchingCoupon.discount_type === 1) {
        // Percentage discount
        discount = (matchingCoupon.discount / 100) * sellingPrice;
      } else if (matchingCoupon.discount_type === 2) {
        // Actual amount discount
        if (isUSD) {
          discount = matchingCoupon.discount_usd;
        } else {
          discount = matchingCoupon.discount;
        }
      }
      sellingPrice = sellingPrice - discount;
      setNewSellingPrice(sellingPrice.toFixed(2));
      setDiscountAmount(discount.toFixed(2));
    } else {
      setCouponFound(false);
      setShowAlert(true);
      addAlert(
        "Expired Coupon",
        "The entered coupon has expired",
        "error",
        true
      );
    }
  } else {
    setCouponFound(false);
    setShowAlert(true);
    addAlert(
      "Not Found",
      "The entered coupon is not applicable",
      "warning",
      true
    );
  }
  setCoupon("");
};

export const requestOTP = async (
  phoneNumber,
  setOtpRequested,
  setShowAlert,
  addAlert
) => {
  const localData = {
    phone: phoneNumber,
    isd_code: 91, //to add country code and support for email otp
  };

  try {
    const res = await open_api_without_auth.post(
      `/auth/login/generate_otp/`,
      localData
    );
    //console.log(res.data);
    if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
      setOtpRequested(true);
      setShowAlert(true);
      addAlert(
        "OTP Sent",
        `OTP is sent to +91 ${phoneNumber}`,
        "success",
        true
      );
      return res?.data;
    }
  } catch (err) {
    //console.log(err);
  }
};

export const validateOTP = async (
  otp,
  phoneNumber,
  setOtpValidated,
  setVerified,
  setUserId,
  setBearerToken,
  setShowAlert,
  addAlert,
  setfirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setIsAuthenticated,
  setIsUserInfoExists,
  setEnablePayButton,
  updateIsUserInfoExists
) => {
  const userInputOtp = otp.join(""); // Combine user-input OTP digits
  const localData = {
    phone: phoneNumber,
    isd_code: 91, //to add country code and support for email otp
    otp: Number(userInputOtp),
  };

  try {
    const res = await open_api_without_auth.post(
      `/auth/login/validate_otp/`,
      localData
    );

    if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
      if (res?.data?.data?.user != null) {
        setOtpValidated(true);
        const userInfoExists = isUserInfoExists(
          res,
          setfirstName,
          setLastName,
          setEmail,
          setPhoneNumber,
          false
        );
        sessionStorage.setItem("useEmail", false);
        sessionStorage.setItem("usePhone", true);
        setIsAuthenticated(true);
        const userDetailsId = res?.data?.data?.user?.id;
        const accessToken = res?.data?.data?.token?.access;
        const userName =
          res?.data?.data?.user?.first_name +
          " " +
          res?.data?.data?.user?.last_name;
        if (userInfoExists) {
          setVerified(true);
        } else {
          const sessionData = sessionStorage.getItem("expertSession");
          if (sessionData) {
            const expertSessionData = JSON.parse(sessionData);
            postExpertUserLink(expertSessionData.expertslug, accessToken);
          }
        }
        if (userInfoExists) {
          setEnablePayButton(true);
          updateIsUserInfoExists(true);
        }
        setIsUserInfoExists(userInfoExists);
        setEnablePayButton();
        updateIsUserInfoExists();
        setUserId(userDetailsId);
        setBearerToken(accessToken);
        localStorage.setItem("bearerToken", accessToken);
        localStorage.setItem("userName", userName);
        localStorage.setItem("userId", userDetailsId);
      }
      return true;
    } else {
      setShowAlert(true);
      addAlert("OTP Error", res?.data?.data?.message, "error", true);
    }
  } catch (err) {
    //console.log(err);
  }
  return false;
};

const isUserInfoExists = (
  res,
  setFirstName,
  setLastName,
  setEmail,
  setPhoneNumber,
  useEmail
) => {
  let firstName = res?.data?.data?.user?.first_name;
  let lastName = res?.data?.data?.user?.last_name;
  let userEmail = res?.data?.data?.user?.email;
  let userPhone = res?.data?.data?.user?.phone;
  if (useEmail) {
    firstName = res?.data?.user?.first_name;
    lastName = res?.data?.user?.last_name;
    userEmail = res?.data?.user?.email;
    userPhone = res?.data?.user?.phone;
  }
  if (firstName) {
    setFirstName(firstName);
  }
  if (lastName) {
    setLastName(lastName);
  }

  if (useEmail) {
    if (userPhone) {
      setPhoneNumber(userPhone);
      setEmail(userEmail);
    }
  } else {
    if (userEmail) {
      setEmail(userEmail);
    }
  }

  if (!firstName || !lastName || (useEmail ? !userPhone : !userEmail)) {
    return false;
  }

  return true;
};

export const checkUserInfoExists = (
  firstName,
  lastName,
  email,
  phone,
  useEmail
) => {
  console.log(firstName, lastName, email, phone, useEmail);
  if (!firstName || !lastName || (useEmail ? !phone : !email)) {
    return false;
  }
  return true;
};

export const addAlert = (
  title,
  message,
  type,
  autoClose = false,
  setAlerts
) => {
  setAlerts((prevAlerts) => [
    ...prevAlerts,
    { id: Date.now(), title, message, autoClose, type },
  ]);
};

export const removeAlert = (id, setAlerts) => {
  setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
};

export const fetchProfileTypes = async (token, setProfileTypes) => {
  try {
    const res = await open_api_with_user_token(token).get(
      "/profiles/register/as"
    );
    if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
      const data = await res.data.results;
      setProfileTypes(data);
    }
  } catch (error) {
    console.error("Error fetching profile types:", error);
  }
};

export const handleCouponChange = (event, setCoupon) => {
  setCoupon(event.target.value);
};

export const fetchUserDetails = async (bearerToken, userId) => {
  try {
    const res = await open_api_with_user_token(bearerToken).get(
      `/profiles/${userId}/`
    );
    if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
      return res?.data;
    }
  } catch (err) {
    //console.log(err);
  }
};

export const fetchExpertDetails = async (expertSlug) => {
  try {
    const res = await open_api_without_auth().get(
      `profiles/experts/${expertSlug}/`
    );
    if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
      return res?.data;
    }
  } catch (err) {
    //console.log(err);
  }
};

export const postExpertUserLink = async (expertSlug, bearerToken) => {
  const localData = {
    expert_slug: expertSlug ?? "",
  };
  try {
    const res = await open_api_with_user_token(bearerToken).post(
      `stats/track-login/`,
      localData
    );
  } catch (err) {}
};

export const postExpertUserPurchase = async (
  expertSlug,
  bearerToken,
  workshopPurchaseId,
  sessionPurchaseId
) => {
  var localData = {};
  if (workshopPurchaseId != null) {
    localData = {
      expert_slug: expertSlug ?? "",
      workshop_id: workshopPurchaseId,
    };
  } else {
    localData = {
      expert_slug: expertSlug ?? "",
      session_id: sessionPurchaseId,
    };
  }
  try {
    const res = await open_api_with_user_token(bearerToken).post(
      `stats/track-purchase/`,
      localData
    );
  } catch (err) {}
};

export const getBanners = async (placement) => {
  try {
    const res = await open_api_without_auth.get(`core/banners/${placement}/`);

    // Check for success status
    if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
      //console.log(res?.data?.results);
      return res?.data?.results;
    }
  } catch (err) {
    console.error("Error fetching banners:", err);
  }
};

export const validateGoogleLoginWithToken = async (
  idToken,
  setOtpValidated,
  setVerified,
  setUserId,
  setBearerToken,
  setShowAlert,
  handleAddAlert,
  setEmail,
  setPhoneNumber,
  setfirstName,
  setLastName,
  setUserName,
  setIsAuthenticated,
  setIsUserInfoExists,
  useEmail,
  setEnablePayButton,
  updateIsUserInfoExists
) => {
  const localData = {
    token: idToken,
  };

  try {
    const res = await open_api_without_auth.post(
      `/auth/google/oauth/`,
      localData
    );
    if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
      if (res?.data?.user != null) {
        setOtpValidated(true);
        const userInfoExists = isUserInfoExists(
          res,
          setfirstName,
          setLastName,
          setEmail,
          setPhoneNumber,
          true
        );
        sessionStorage.setItem("useEmail", true);
        sessionStorage.setItem("usePhone", false);
        const userDetailsId = res?.data?.user?.id;
        const accessToken = res?.data?.token?.access;
        const userName =
          res?.data?.user?.first_name + " " + res?.data?.user?.last_name;
        setUserName(userName);
        if (userInfoExists) {
          setVerified(true);
        } else {
          const sessionData = sessionStorage.getItem("expertSession");
          if (sessionData) {
            const expertSessionData = JSON.parse(sessionData);
            postExpertUserLink(expertSessionData.expertslug, accessToken);
          }
        }
        if (userInfoExists) {
          setEnablePayButton(true);
          updateIsUserInfoExists(true);
        }
        setIsUserInfoExists(userInfoExists);
        setEmail(res?.data?.user?.email);
        setUserId(userDetailsId);
        setBearerToken(accessToken);
        localStorage.setItem("bearerToken", accessToken);
        localStorage.setItem("userName", userName);
        localStorage.setItem("userId", userDetailsId);
        setIsAuthenticated(true);
      }
    } else {
      setShowAlert(true);
      handleAddAlert("Login Error", res?.data?.message, "error", true);
    }
  } catch (err) {
    //console.log(err);
  }
};
