import React, { useState, useEffect } from "react";
import { CheckCircleOutline } from "@mui/icons-material";
import AccountDeleteCard from "../../Components/AccountDeletion/AccountDeleteCard";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Components/Hook/useAuth";
import { open_api_with_user_token } from "../../Utils/network";
import LoadingOverlay from "../../Components/Dialog/LoadingOverlay";
import successAnimation from "../../Assets/lottie/successlottie.json";
import failureAnimation from "../../Assets/lottie/failureLottie.json";
import Lottie from "react-lottie-player";

const AccountDeletedPage = () => {
  const [isDeleted, setIsDeleted] = useState(false);
  const [countdown, setCountdown] = useState(8);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleAccountDelete = async () => {
    setLoading(true);
    try {
      const bearerToken = localStorage.getItem("bearerToken");
      const response =
        await open_api_with_user_token(bearerToken).delete(
          "/auth/delete_user/"
        );
      if (
        response?.status === 200 ||
        response?.status === 201 ||
        response?.status === 204
      ) {
        setTimeout(() => {
          setIsDeleted(true);
          sessionStorage.clear();
          logout();
          setIsSuccess(true);
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("Error deleting account", error);
    } finally {
    }
  };

  useEffect(() => {
    if (isDeleted) {
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            navigate("/");
            window.location.reload();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isDeleted, navigate]);

  if (isDeleted) {
    const lottieAnimation = isSuccess ? successAnimation : failureAnimation;
    if (isSuccess) {
      return (
        <div style={styles.container}>
          <Lottie
            loop={false}
            play
            animationData={lottieAnimation}
            style={{ height: 150 }}
          />
          <h1 style={styles.title}>Your account has been deleted</h1>
          <p style={styles.subtitle}>Thanks for using our product!</p>
          <p style={styles.subtitle}>We look forward to seeing you again.</p>
          <p style={styles.subtitle}>
            Redirecting to the homepage in {countdown} seconds...
          </p>
        </div>
      );
    } else {
      return (
        <div style={styles.container}>
          <Lottie
            loop={false}
            play
            animationData={lottieAnimation}
            style={{ height: 150 }}
          />
          <h1 style={styles.title}>Your account was not deleted</h1>
          <p style={styles.subtitle}>Please contact support</p>
          <p style={styles.subtitle}>
            There was an issue while deleting your account. If issue persists
            please contact support
          </p>
          <p style={styles.subtitle}>
            Redirecting to the homepage in {countdown} seconds...
          </p>
        </div>
      );
    }
  }

  return (
    <>
      {loading ? <LoadingOverlay /> : <></>}
      <div style={styles.container}>
        <AccountDeleteCard
          onClose={() => navigate("/profile")}
          onDelete={handleAccountDelete}
        />
      </div>
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
    padding: "0 20px",
  },
  icon: {
    fontSize: "60px",
    color: "#d63384",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#212529",
  },
  subtitle: {
    fontSize: "16px",
    color: "#6c757d",
  },
};

export default AccountDeletedPage;
