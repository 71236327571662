import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  DateList,
  DateItem,
  TimeSlots,
  TimeSlot,
  ScrollButton,
  ProceedButton,
  ImageContainer,
  CenteredImage,
  Duration,
} from "./DateAvailabilityStyles";
import leftArrow from "../../Assets/Logo_icons/arrow_back.svg";
import rightArrow from "../../Assets/Logo_icons/forward_arrow.svg";
import { open_api } from "../../Utils/network";
import noSlot from "../../Assets/course_page/noSlots.png";
import { PayButton } from "./PaymentDialogStyles";
import { duration } from "@mui/material";

const generateDates = (days) => {
  const dates = [];
  const today = new Date();
  for (let i = 0; i < days; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    dates.push(date);
  }
  return dates;
};

const DateAvailability = ({
  expertId,
  onDateChange,
  onTimeSlotChange,
  onProceedToPayment,
  onSelectedSlot,
  isUserDataRequired,
  onDurationChange,
  packageDuration,
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const dates = generateDates(30);

  useEffect(() => {
    const fetchSlots = async () => {
      const selectedDateStr = selectedDate.toISOString().split("T")[0];
      try {
        const response = await open_api.get(
          `/session/slot_list?date=${selectedDateStr}&expert=${expertId}&duration=${packageDuration}`
        );
        setTimeSlots(response?.data?.data);
      } catch (error) {
        console.error("Error fetching time slots:", error);
      }
    };

    fetchSlots();
  }, [selectedDate, expertId]);

  useEffect(() => {
    onDateChange(selectedDate);
  }, [selectedDate, onDateChange]);

  useEffect(() => {
    onTimeSlotChange(selectedTimeSlot);
  }, [selectedTimeSlot, onTimeSlotChange]);

  useEffect(() => {
    onDurationChange(selectedDuration);
  }, [selectedDuration, onDurationChange]);

  useEffect(() => {
    onSelectedSlot(selectedSlot);
  }, [selectedSlot, onSelectedSlot]);

  const scrollLeft = () => {
    document.getElementById("dateList").scrollBy({
      left: -80,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    document.getElementById("dateList").scrollBy({
      left: 80,
      behavior: "smooth",
    });
  };

  const handleTimeSlotClick = (slot) => {
    if (slot.available) {
      setSelectedTimeSlot(slot.time);
      setSelectedDuration(convertDurationToMinutes(slot.duration));
      setSelectedSlot(slot);
      isUserDataRequired(slot.is_user_data_required);
    }
  };

  const formatTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date();
    date.setHours(hours, minutes, seconds);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const convertDurationToMinutes = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 60 + minutes + Math.round(seconds / 60);
  };

  return (
    <Container>
      <div style={{ display: "flex", alignItems: "center" }}>
        <ScrollButton onClick={scrollLeft}>
          <img src={leftArrow} alt="Scroll Left" />
        </ScrollButton>
        <DateList id="dateList">
          {dates.map((date, index) => (
            <DateItem
              key={index}
              selected={
                selectedDate.toISOString().split("T")[0] ===
                date.toISOString().split("T")[0]
              }
              onClick={() => setSelectedDate(date)}
            >
              {date.toDateString().split(" ")[0]} <br />
              {date.getDate()} <br />
              {date.toLocaleString("default", { month: "short" })}
            </DateItem>
          ))}
        </DateList>
        <ScrollButton onClick={scrollRight}>
          <img src={rightArrow} alt="Scroll Right" />
        </ScrollButton>
      </div>
      <h4>Select a time of day</h4>
      <div>
        {timeSlots && timeSlots.length > 0 ? (
          <TimeSlots>
            {timeSlots.map((slot) => (
              <TimeSlot
                key={slot.id}
                available={slot.available}
                selected={selectedTimeSlot === slot.time}
                onClick={() => handleTimeSlotClick(slot)}
              >
                {formatTime(slot.time)}
                <Duration
                  available={slot.available}
                  selected={selectedTimeSlot === slot.time}
                >
                  {convertDurationToMinutes(slot.duration)} mins
                </Duration>
              </TimeSlot>
            ))}
          </TimeSlots>
        ) : (
          <ImageContainer>
            <CenteredImage src={noSlot} alt="No time slots available" />
          </ImageContainer>
        )}
        All time are in UTC +5:30
      </div>
      <PayButton onClick={onProceedToPayment}>Proceed to payment</PayButton>
    </Container>
  );
};

export default DateAvailability;
